import Vue from 'vue';

/**
 * Creates and injects GTM functionality into vueInstance instance
 * @class TagManager
 */

class TagManager {
    /**
     * @constructor
     * @param {String} gtmId
     * @param {Object} queryParams
     * @param {String} dataLayerName
     * @param {Window} window
     */
    constructor({ gtmId, queryParams = {}, dataLayerName = 'dataLayer', window }) {
        this.gtmId = gtmId;
        this.queryParams = queryParams;
        this.dataLayerName = dataLayerName;
        this.url = 'https://www.googletagmanager.com/gtm.js';
        this.window = window;

        this.init();
    }

    init() {
        this.appendScriptTag();
        this.appendNoScriptTag();
        this.injectGTMIntoVueInstance();
    }

    /**
     * Creates and returns GTM script url
     * @returns {string} scriptUrl
     */
    getScriptUrl() {
        const queryParams = {
            id: this.gtmId,
            l: this.dataLayerName,
            ...this.queryParams
        };

        const queryString = Object.keys(queryParams)
            .filter(key => queryParams[key] !== null && queryParams[key] !== undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
            .join('&');

        return `${this.url}?${queryString}`;
    }

    /**
     * Create and appends GTM script tag
     */
    appendScriptTag() {
        const document = this.window.document;

        this.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });

        const tagManagerScript = document.createElement('script');
        tagManagerScript.async = true;
        tagManagerScript.src = this.getScriptUrl();

        const firstScriptFound = document.getElementsByTagName('script')[0];
        firstScriptFound.parentNode.insertBefore(tagManagerScript, firstScriptFound);
    }

    /**
     * Creates and appends noscript tag
     */
    appendNoScriptTag() {
        const document = this.window.document;
        const noScriptTag = document.createElement('noscript');
        const iframeTag = document.createElement('iframe');
        iframeTag.height = 0;
        iframeTag.width = 0;
        iframeTag.style.visibility = 'hidden';
        iframeTag.style.display = 'none';
        iframeTag.src = `https://www.googletagmanager.com/ns.html?id=${this.gtmId}`;
        noScriptTag.appendChild(iframeTag);
        document.body.prepend(noScriptTag);
    }

    constructCheckoutData(eventName, checkout, priceData) {
        return {
            event: eventName,
            productTitle: checkout.productTitleForText || checkout.title, //checkout.title is for checkout-for-order
            productPrice: Object.keys(priceData).length > 0 ? priceData.singleProductPrice / 100 : 0
        };
    }

    constructProductDetailsData(productDetails) {
        if (productDetails.soldOut) {
            return {
                event: 'view_item',
                ecommerce: {
                    currency: 'EUR',
                    items: []
                }
            };
        }
        if (productDetails.productCategory === 'valuevoucher' || productDetails.productCategory === 'multivoucher') {
            return {
                event: 'view_item',
                ecommerce: {
                    currency: 'EUR',
                    items: [this.constructEcommerceItemFromValueVoucherProduct(productDetails)]
                }
            };
        }

        if (productDetails.scheduledArrivalAvailable && !productDetails.variants.length) {
            // We need to extract a list of all variants from the date/variant-list structure
            // However, we need this list to be distinct - therefore we create a Map using the articlenumber of the
            // variants as key to achieve the uniqueness per articlenumber. We only use the values() property of this
            // new map and use the spread operator to convert the iterator to the uniqueVariants variable.
            let uniqueVariants = [
                ...new Map(
                    productDetails.variantsWithScheduledArrival
                        .map(entry => entry.variants) // just fetch the variants from each date entry
                        .flat(1) // we now have a list of lists, so we flatten this
                        .map(entry => [entry.articleNumber, entry]) // map each variant to an array of articelnumber and variant
                ).values()
            ];
            return {
                event: 'view_item',
                ecommerce: {
                    currency: 'EUR',
                    items: uniqueVariants.map(variant =>
                        this.constructEcommerceItemFromVariant(productDetails, variant)
                    )
                }
            };
        }
        return {
            event: 'view_item',
            ecommerce: {
                currency: 'EUR',
                items: productDetails.variants.map(variant =>
                    this.constructEcommerceItemFromVariant(productDetails, variant)
                )
            }
        };
    }

    constructEcommerceItemFromVariant(productDetails, variant) {
        const isValueVoucher =
            productDetails.productCategory === 'valuevoucher' || productDetails.productCategory === 'multivoucher';
        return {
            item_id: (isValueVoucher ? 'vv-' : 'hv-') + productDetails.id,
            item_name: productDetails.title,
            item_brand: productDetails.hotel,
            item_category: isValueVoucher
                ? productDetails.productCategory
                : (productDetails.auctionOffer ? 'auctionoffer' : this.getCategoryForVouchertype(productDetails.hotelVoucherType)),
            item_variant: variant.articleNumber,
            price: ((variant.price.regularPriceValues && variant.price.regularPriceValues.price) || 0) / 100,
            quantity: 1
        };
    }

    constructEcommerceItemFromValueVoucherProduct(productDetails) {
        return {
            item_id: 'vv-' + productDetails.id,
            item_name: productDetails.title,
            item_brand: 'Animod',
            item_category: productDetails.productCategory,
            item_variant: productDetails.articleNumber,
            price:
                ((productDetails.price.regularPriceValues && productDetails.price.regularPriceValues.price) || 0) / 100,
            quantity: 1
        };
    }

    getCategoryForVouchertype(hotelVoucherType) {
        let category;
        if (hotelVoucherType === 'RegularHotelVoucher') {
            category = 'hotelvoucher';
        } else if (hotelVoucherType === 'WebshopAccessCode') {
            category = 'accesscode';
        } else if (hotelVoucherType === 'ExternalMultiVoucher') {
            category = 'externalmultivoucher';
        } else if (hotelVoucherType === 'ExternalTravelVoucher') {
            category = 'externaltravelvoucher';
        } else if (hotelVoucherType === 'OtherExternalVoucher') {
            category = 'externalothervoucher';
        }
        return category;
    }

    constructProductDetailsDataLegacy(productDetails) {
        let price;
        if (productDetails.price) {
            price =
                ((productDetails.price.regularPriceValues &&
                    productDetails.price.regularPriceValues.minimumPrice &&
                    productDetails.price.regularPriceValues.minimumPrice.price) ||
                    0) / 100;
        }
        return {
            event: 'productdetails',
            productId: productDetails.id,
            productTitle: productDetails.title,
            productPrice: price
        };
    }

    constructOrderData(order, isMultivoucher) {
        const formatCents = eurocents => {
            if (eurocents === 0) {
                return '0';
            }
            const s = eurocents.toString();
            return s.substring(0, s.length - 2) + '.' + s.substring(s.length - 2);
        };

        // define values used multiple times in payload
        let {
            title,
            orderNumber,
            totalPrice: totalPriceInitial,
            totalProductPrice,
            singleProductPrice,
            discount,
            discountCode,
            shipmentCosts,
            productId: productIdInitial,
            voucherProductId,
            quantity,
            billingAddress: {
                street: customerFullStreet,
                salutation: customerSalutation,
                firstname: customerFirstname,
                lastname: customerLastname,
                zipcode: customerZipcode,
                city: customerCity,
                country
            },
            hotelVoucherData,
            productCategory,
            articleNumber,
            shipment,
            email: customerEmail
        } = order;

        //reformat
        const totalPrice = formatCents(totalPriceInitial);
        totalProductPrice = formatCents(totalProductPrice);
        singleProductPrice = formatCents(singleProductPrice);

        const isHotelVoucherOrder =
            hotelVoucherData !== undefined && hotelVoucherData !== null && Object.keys(hotelVoucherData).length !== 0;
        const productId = isHotelVoucherOrder ? 'hv-' + productIdInitial : 'vv-' + voucherProductId;
        const orderValue = formatCents(totalPriceInitial - shipmentCosts);
        const discountValue = formatCents(discount || 0);
        const discountedOrderValue = formatCents(totalPriceInitial - shipmentCosts - discount);
        const shipping = formatCents(shipmentCosts);
        const shop = isMultivoucher ? 'Animod Multigutschein Shop' : 'Animod Webshop';
        const brand = isHotelVoucherOrder ? hotelVoucherData.hotelName : 'Animod';
        const customerStreet = customerFullStreet
            ? customerFullStreet.substring(0, customerFullStreet.lastIndexOf(' '))
            : '';
        const customerStreetNumber = customerFullStreet
            ? customerFullStreet.substring(customerFullStreet.lastIndexOf(' ') + 1)
            : '';

        return {
            event: 'eec.purchase',
            ecommerce: {
                purchase: {
                    actionField: {
                        id: orderNumber,
                        affiliation: shop,
                        revenue: totalPrice,
                        shipping,
                        tax: '0',
                        coupon: discountCode || null
                    },
                    products: [
                        {
                            id: productId,
                            name: title,
                            category: productCategory,
                            brand,
                            variant: articleNumber,
                            price: singleProductPrice,
                            quantity
                        }
                    ]
                }
            },
            transactionId: orderNumber,
            total: totalPrice,
            shop,
            totalProductPrice,
            shipping,
            orderValue,
            discountedOrderValue,
            discountValue,
            discountCode,
            productId,
            brand,
            productTitle: title,
            productPrice: singleProductPrice,
            productCategory,
            articleNumber,
            quantity,
            customerEmail,
            customerSalutation,
            customerFirstname,
            customerLastname,
            customerFullStreet,
            customerStreet,
            customerStreetNumber,
            customerZipcode,
            customerCity,
            consumerCountry: country ? country.isoCode : null,
            addressDataRequired: shipment.addressDataRequired ? shipment.addressDataRequired : false,
            cart: [{ id: productId, price: singleProductPrice, quantity }]
        };
    }

    /**
     * Push an event to the data layer
     * @param {Object} event
     */
    push(event) {
        if (!(event instanceof Object) || event instanceof Array) {
            throw 'Pushed event is not an object.';
        }

        const window = this.window;
        const dataLayerName = this.dataLayerName;
        window[dataLayerName] = window[dataLayerName] || [];
        window[dataLayerName].push(event);
    }

    pushCustomEvent(eventName) {
        this.push({
            event: eventName
        });
    }

    clearEcommerce() {
        this.push({
            ecommerce: null
        });
    }

    pushPageLoadedEvent() {
        this.pushCustomEvent('pageLoaded');
    }

    injectGTMIntoVueInstance() {
        Vue.prototype.$gtm = {
            dataLayer: this.window[this.dataLayerName],
            push: this.push.bind(this),
            pushSuccessData: function (order, isMultivoucher) {
                this.pushPageLoadedEvent();
                this.push(this.constructOrderData(order, isMultivoucher));
                this.clearEcommerce();
                this.push({
                    event: 'purchase',
                    ecommerce: {
                        transaction_id: order.orderNumber,
                        affiliation: '' + order.platformName,
                        orderPlatform: '' + order.orderPlatformName,
                        xopValue: '' + order.xopValue,
                        currency: 'EUR',
                        shipping: (order.shippingCosts || 0) / 100,
                        tax: 0.0,
                        value: (order.totalPrice - (order.discount || 0)) / 100,
                        coupon: order.discountCode ? order.discountCode : null,
                        items: [
                            {
                                item_id: order.trackingData.itemId,
                                item_name: order.trackingData.itemName,
                                item_brand: order.trackingData.itemBrand,
                                item_category: order.trackingData.itemCategory,
                                item_variant: order.trackingData.itemVariant,
                                price: (order.singleProductPrice || 0) / 100,
                                quantity: order.quantity
                            }
                        ]
                    }
                });
            }.bind(this),
            pushCheckoutData: function (checkout, priceData) {
                this.pushPageLoadedEvent();

                // Push legacy event
                this.push(this.constructCheckoutData('checkout', checkout, priceData));
            }.bind(this),
            pushCheckoutForOrderData: function (checkout, priceData) {
                this.pushPageLoadedEvent();
                const data = this.constructCheckoutData('checkoutForOrder', checkout, priceData);
                this.push(data);
            }.bind(this),
            pushProductDetailsData: function (productDetails) {
                this.pushPageLoadedEvent();
                this.push(this.constructProductDetailsDataLegacy(productDetails));
                this.clearEcommerce();
                this.push(this.constructProductDetailsData(productDetails));
            }.bind(this),
            pushHotelDetailsPageData: function (hotelDetails) {
                this.pushPageLoadedEvent();
                this.pushCustomEvent('hoteldetails');
            }.bind(this),
            pushValueVoucherDetailsData: function (valueVoucherDetails) {
                this.pushPageLoadedEvent();
                this.clearEcommerce();
                const data = this.constructProductDetailsData(valueVoucherDetails);
                this.push(data);
            }.bind(this),
            pushProductSelection: function (productDetails, variant) {
                this.clearEcommerce();
                const data = {
                    event: 'add_to_cart',
                    ecommerce: {
                        currency: 'EUR',
                        value:
                            ((variant.price.regularPriceValues && variant.price.regularPriceValues.price) || 0) / 100,
                        items: [this.constructEcommerceItemFromVariant(productDetails, variant)]
                    }
                };
                this.push(data);
            }.bind(this),
            pushBeginCheckout: function (remainingAmountToPay, item, shippingMethod, paymentMethod) {
                this.clearEcommerce();
                this.push({
                    event: 'begin_checkout',
                    payment_type: paymentMethod.name,
                    shipping_tier: shippingMethod,
                    ecommerce: {
                        currency: 'EUR',
                        value: remainingAmountToPay / 100,
                        items: [item]
                    }
                });
            }.bind(this),
            pushShippingMethodChanged: function (remainingAmountToPay, item, shippingMethod, paymentMethod) {
                this.clearEcommerce();
                this.push({
                    event: 'add_shipping_info',
                    payment_type: paymentMethod.name,
                    shipping_tier: shippingMethod,
                    ecommerce: {
                        currency: 'EUR',
                        value: remainingAmountToPay / 100,
                        items: [item]
                    }
                });
            }.bind(this),
            pushPaymentMethodChanged: function (remainingAmountToPay, item, shippingMethod, paymentMethod) {
                this.clearEcommerce();
                this.push({
                    event: 'add_payment_info',
                    payment_type: paymentMethod.name,
                    shipping_tier: shippingMethod,
                    ecommerce: {
                        currency: 'EUR',
                        value: remainingAmountToPay / 100,
                        items: [item]
                    }
                });
            }.bind(this),
            pushPageView: function (eventName) {
                this.pushPageLoadedEvent();
                this.pushCustomEvent(eventName);
            }.bind(this),
            pushEvent(event) {
                this.push({ event });
            }
        };
    }
}

export default TagManager;
