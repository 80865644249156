import TagManager from '@/models/TagManager';
import consentManagerScript from '@/utils/consentManagerScript';

export default {
    data() {
        return {
            showCookiesNotice: false //animod's own cookie banner
        };
    },
    computed: {
        globalMeta() {
            return this.$store.state.globalMeta;
        },
        showHeader() {
            return ['default', 'cooperation'].includes(this.globalMeta.headerVariant);
        },
        isCheckoutRoute() {
            return !!this.$route.meta.isCheckoutRoute;
        },
        isDetailsRoute() {
            return !!this.$route.meta.isDetailsRoute;
        },
        isCategoryRoute() {
            return !!this.$route.meta.isCategoryRoute;
        },
        isSuccessRoute() {
            return this.$route.name === 'success';
        },
        isNewsletterRoute() {
            return (
                !!this.$route.meta.isNewsletterRoute ||
                (this.isCategoryRoute &&
                    (this.$route.path === '/c/newsletter-confirmed' ||
                        this.$route.path === '/c/newsletter-failure' ||
                        this.$route.path === '/c/newsletter-unsubscribed'))
            );
        },
        navigationEntries() {
            return this.globalMeta.navigationEntries || [];
        },
        showNavigation() {
            return this.$store.state.showNavigation && this.navigationEntries.length && !this.isCheckoutRoute;
        },
        showMobileNavigation() {
            return !this.isDetailsRoute && !this.isCheckoutRoute && !!this.navigationEntries.length;
        },
        showNewsletter() {
            return (
                this.$store.state.globalMeta.newsletterFormEnabled &&
                !this.isDetailsRoute &&
                !this.isSuccessRoute &&
                !this.isCheckoutRoute &&
                !this.isNewsletterRoute
            );
        },
        showMobileSeparateFooter() {
            return !this.navigationEntries.length && !!this.$getSafe(this.globalMeta, 'footerLinks.length');
        }
    },
    methods: {
        initConsentManager(consentManagerId, googleConsentModeEnabled) {
            if (process.client && consentManagerId) {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    dataLayer.push(arguments);
                }
                if (googleConsentModeEnabled) {
                    gtag('consent', 'default', {
                        ad_storage: 'denied',
                        analytics_storage: 'denied',
                        wait_for_update: 500
                    });
                }

                dataLayer.push({
                    event: 'default_content'
                });

                // https://help.consentmanager.net/books/cmp/page/client-side-configuration-options
                window.cmp_stayiniframe = true;

                consentManagerScript(window, consentManagerId);

                this.$isDev && console.log(`Integrated the CMP with id: ${consentManagerId}`);
            }
        },
        initTagManager(tagManagerId, consentManagerId, googleConsentModeEnabled) {
            if (process.client && !this.outsideIframe) {
                this.initConsentManager(consentManagerId, googleConsentModeEnabled);

                if (tagManagerId) {
                    new TagManager({
                        gtmId: tagManagerId,
                        window
                    });

                    this.$isDev && console.log(`Integrated the GTM with id: ${tagManagerId}`);
                }
            }
        },
        showDNTBanner() {
            if (process.client) {
                this.$nextTick(() => {
                    const message = this.$t('doNotTrack.banner', {
                        break: '<br />',
                        link: `<a href="/c/Datenschutz" title="Datenschutz">${this.$t('DataProtection.link')}</a>`
                    });
                    const sessionStorageBannerKey = 'showDTNBanner';
                    if (!window.sessionStorage || window.sessionStorage.getItem(sessionStorageBannerKey) !== 'no') {
                        this.$addBottomFloat &&
                            this.$addBottomFloat('<div class="dnt-banner">' + message + '</div>', 7000);
                        window.sessionStorage && window.sessionStorage.setItem(sessionStorageBannerKey, 'no');
                    }
                });
            }
        },
        hasDoNotTrackEnabled() {
            if (process.client) {
                const dnt = window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack;
                if (dnt === 1 || dnt === '1' || dnt === 'yes') {
                    return true;
                }
                if (window.external && 'msTrackingProtectionEnabled' in window.external) {
                    return window.external.msTrackingProtectionEnabled();
                }
                return false;
            }
        },
        processTracking(googleTagManagerId) {
            // all used here methods and their descendants are good to be moved to Composition API after nuxt 3 migration
            if (!this.hasDoNotTrackEnabled()) {
                this.initTagManager(
                    googleTagManagerId,
                    this.$store.state.globalMeta.consentManagerId,
                    this.$store.state.globalMeta.googleConsentModeEnabled
                );
            } else {
                this.showDNTBanner();
            }
        }
    },
    watch: {
        //Watcher since globalMeta is async
        'globalMeta.googleTagManagerId': {
            handler: 'processTracking',
            immediate: true
        }
    },
    created() {
        this.showCookiesNotice =
            this.$cookies.cookies_notice !== 'no' &&
            this.$store.state.ident !== 'sovendus' &&
            this.$store.state.ident !== 'nettovorteilswelt';
    }
};
