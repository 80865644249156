export default {
    head() {
        return {
            htmlAttrs: {
                lang: this.$i18n.locale()
            }
        };
    },
    methods: {
        initLocalization() {
            //For cases when getTranslations has failed
            if (!this.$store.state.translations.result || this.$isEmptyObject(this.$store.state.translations.result)) {
                //otherwise the this.$i18n && !!this.$i18n.localeExists(this.$store.state.language) will be true
                return;
            }
            this.$i18n.add(this.$store.state.language, { ...this.$store.state.translations.result });
            this.$i18n.set(this.$store.state.language);
            this.$i18n.fallback('de');

            //cleaning up unnecessary copy of translations
            /*
                !important. Nuxt executes created hook twice - on a server side and on a client side.
                Therefore, checking for client allows to perform task only on the second created call.
                It's important to clean up store on the last call, otherwise client side will get already
                cleaned store, which it copies from
             */
            if (process.client) {
                const { etag, modified } = this.$store.state.translations;
                this.$store.commit('setTranslations', {
                    etag,
                    modified,
                    result: {}
                });
            }

            this.addTranslationGlobalMethods();
        },
        addTranslationGlobalMethods() {
            if (process.client && this.$isDev) {
                //backoffice auth token is needed
                const context = this;

                window.$t = {
                    set(key, value) {
                        context.$store.dispatch('_updateTranslation', { key, value });
                    },
                    get() {
                        return context.$t(...arguments);
                    },
                    findKey(value) {
                        const translations = context.$store.state.i18n.translations[context.$i18n.locale()];
                        return Object.keys(translations).find(key => translations[key] === value);
                    },
                    findContaining(pattern) {
                        const translations = context.$store.state.i18n.translations[context.$i18n.locale()];
                        const lowerCasePattern = pattern.toLowerCase();
                        const found = Object.keys(translations).reduce((final, current) => {
                            if (translations[current].toLowerCase().includes(lowerCasePattern)) {
                                final[current] = translations[current];
                            }
                            return final;
                        }, {});
                        console.table(found);
                    },
                    findKeysContaining(pattern) {
                        const translations = context.$store.state.i18n.translations[context.$i18n.locale()];
                        const lowerCasePattern = pattern.toLowerCase();
                        const found = Object.entries(translations).filter(
                            ([key, value]) => ~key.toLowerCase().indexOf(lowerCasePattern)
                        );
                        console.table(found);
                    }
                };
            }
        }
    },
    created() {
        //Initializing localization in created hook, since it's called on both
        // server and client side, resulting in the same rendered content
        this.initLocalization();
    }
};
