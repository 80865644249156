export default {
    head() {
        const isMainShop = this.$store.getters.isMainShop;

        if (isMainShop) {
            return {
                link: [{ rel: 'manifest', href: '/manifest.json' }]
            };
        }
        return {};
    },
    computed: {
        theme() {
            return this.$store.state.theme;
        }
    },
    methods: {
        injectTheme() {
            if (this.theme) {
                //lazy-once webpack mode is important to preserve the production chunks' size
                //import(`@/themes/${this.theme}/styles/theme.scss`);
                import(`@/themes/${this.theme}/styles/theme.scss`).then(
                    module => {
                        if (module.__inject__) {
                            module.__inject__(this.$ssrContext);
                        }
                    },
                    () => {
                        //there is no theme.scss, then main.scss is sufficient
                        this.importDefaultStylesheet();
                    }
                );
            } else {
                this.importDefaultStylesheet();
            }
        },

        importDefaultStylesheet() {
            import(`@/styles/main.scss`).then(module => {
                if (module.__inject__) {
                    module.__inject__(this.$ssrContext);
                }
            });
        }
    },
    created() {
        this.injectTheme();
    }
};
